import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { HttpService } from '../services/http.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private httpService: HttpService) {}
  canActivate(): boolean {
    if (this.httpService.isUserAuthorize) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
