import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { HttpService, userDetails } from './services/http.service';
import { userData } from './store/store.selector';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private menu: MenuController,
    private httpService: HttpService,
    private store: Store
  ) {}

  subscription: Subscription = this.store.select(userData).subscribe((res) => {
    this.user = res;
  });

  user: userDetails = this.httpService.user;
}
