import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './gaurds/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./folder/folder.module').then((m) => m.FolderPageModule),
  },
  {
    path: 'sign-up',
    loadChildren: () =>
      import('./sign-up/sign-up.module').then((m) => m.SignUpPageModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'add-new-device',
    loadChildren: () =>
      import('./add-new-device/add-new-device.module').then(
        (m) => m.AddNewDevicePageModule
      ),
  },
  {
    path: 'device-history',
    loadChildren: () =>
      import('./device-history/device-history.module').then(
        (m) => m.DeviceHistoryPageModule
      ),
  },
  {
    path: 'edit-device-details',
    loadChildren: () =>
      import('./edit-device-details/edit-device-details.module').then(
        (m) => m.EditDeviceDetailsPageModule
      ),
  },
  {
    path: 'map',
    loadChildren: () => import('./map/map.module').then( m => m.MapPageModule)
  },
  {
    path: '**',
    loadChildren: () =>
      import('./folder/folder.module').then((m) => m.FolderPageModule),
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
