import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LoadingControllerService {
  loader: HTMLIonLoadingElement;
  toast: HTMLIonToastElement;
  constructor(
    private loadingController: LoadingController,
    private toastController: ToastController
  ) {}

  async presentLoading(message?: string) {
    this.loader = await this.loadingController.create({
      message: message ? message : 'Loging In...',
      backdropDismiss: false,
      showBackdrop: true,
      animated: true,
      cssClass: 'spinner',
    });
    return this.loader.present();
  }

  async dismiss() {
    await this.loader.dismiss();
  }

  async presentToast(msg: string) {
    this.toast = await this.toastController.create({
      message: msg,
      animated: true,
      duration: 2000,
      color: 'light',
      mode: 'ios',
      cssClass: 'toast_alert',
    });
    return await this.toast.present();
  }

  async cancelToast() {
    return await this.toast.dismiss();
  }
}
