import { createSelector } from '@ngrx/store';
import { initaldataIneterface } from './store.reducer';

export interface appState {
  data: initaldataIneterface;
}

export const storeData = (state: appState) => state.data;

export const userData = createSelector(storeData, (state) => {
  return state.user;
});
