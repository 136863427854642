import { createReducer, on, State } from '@ngrx/store';
import { userDetails } from '../services/http.service';
import { updateUserData } from './store.actions';

export interface initaldataIneterface {
  user: userDetails;
}

const inititalData: initaldataIneterface = {
  user: {
    id: null,
    name: 'Guest',
    userName: 'Guest@123',
    email: '',
    role: 'user',
  },
};

export const adduserData = createReducer(
  inititalData,
  on(updateUserData, (state, actions) => {
    return {
      ...state,
      user: actions.user,
    };
  })
);
