import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http, HttpOptions, HttpResponse } from '@capacitor-community/http';
import { ToastController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { updateUserData } from '../store/store.actions';
import { LoadingControllerService } from './loading-controller.service';
import { environment } from '../../environments/environment';
export interface userDetails {
  name: string;
  userName: string;
  id: string;
  email: string;
  role: 'user' | 'admin';
}

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  public baseUrl = environment.baseUrl;

  private isAutoLogin: any = localStorage.getItem('isAutoLogin')
    ? localStorage.getItem('isAutoLogin')
    : null;

  token: string = localStorage.getItem('token')
    ? localStorage.getItem('token')
    : '';
  isUserAuthorize: boolean = false;

  public user: userDetails = {
    id: null,
    name: 'Guest',
    userName: 'Guest',
    email: 'Guest@Email.com',
    role: 'user',
  };

  constructor(
    private tostController: ToastController,
    private router: Router,
    private store: Store,
    private loader: LoadingControllerService
  ) {
    if (this.token.length > 2 && this.isAutoLogin == 'true') {
      this.autoLogin();
    }
  }

  async autoLogin() {
    await this.loader.presentLoading();
    this.get('/auth/verifyUser')
      .then(async (response) => {
        if (response.data.success) {
          this.isUserAuthorize = true;
          this.user = response.data.user;
          this.store.dispatch(updateUserData({ user: this.user }));
          this.router.navigate(['/dashboard']);
          await this.loader.dismiss();
          // console.log(this.user);
        } else {
          this.isUserAuthorize = false;
          await this.loader.dismiss();
        }
        // console.log('user status ', this.isUserAuthorize);
        // console.log(response);
      })
      .catch(async (err) => {
        await this.loader.dismiss();
      });
  }

  async post(url: string, data?: any) {
    const options: HttpOptions = {
      url: this.baseUrl + url,
      headers: {
        'Content-Type': 'application/json',
        authorization: `bearer ${this.token}`,
      },
      data: data,
    };

    try {
      const response: HttpResponse = await Http.post(options);
      return response;
    } catch (err) {
      console.log(err);
      this.presenttost(err);
      return err;
    }
  }

  async presenttost(message: string) {
    const toast = await this.tostController.create({
      message: message,
      duration: 2000,
      color: 'light',
      animated: true,
      mode: 'ios',
      cssClass: 'toast_alert',
    });
    return toast.present();
  }

  async get(url: string) {
    const options: HttpOptions = {
      url: this.baseUrl + url,
      headers: {
        authorization: `bearer ${this.token}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response: HttpResponse = await Http.get(options);
      return response;
    } catch (err) {
      this.presenttost(err);
      return err;
    }
  }

  setToken(token: string) {
    // console.log(token);
    this.token = token;
    localStorage.setItem('token', token);
  }

  getToken() {
    this.token = localStorage.getItem('token');
    return this.token;
  }

  clearToken() {
    this.token = '';
    localStorage.clear();
  }

  setisAutoLogin(status: boolean) {
    this.isAutoLogin = status;
    return localStorage.setItem('isAutoLogin', this.isAutoLogin);
  }
  getisAutoLogin() {
    return localStorage.getItem('isAutoLogin');
  }
}
